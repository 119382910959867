import organism from '../../images/monitorsPng/有机物.png';
import NH3N from '../../images/monitorsPng/氨氮.png';
import NO3 from '../../images/monitorsPng/总磷.png';
import NH3NSecond from '../../images/monitorsPng/氨氮（水杨酸法）.png';
import COD from '../../images/monitorsPng/COD.png';
import TN from '../../images/monitorsPng/总磷.png';
import Cl from '../../images/monitorsPng/氨氮.png';
import organismPdf from '../../pdf/LOC有机物自动分析仪用户手册_V2.0_fmt_pdf.pdf';
import NH3NPdf from '../../pdf/LOC氨氮自动分析仪用户手册_V2.0_fmt_pdf.pdf';
import NO3Pdf from '../../pdf/LOC硝酸盐自动分析仪用户手册_V2.0_fmt_pdf.pdf';
import NH3NSecondPdf from '../../pdf/LOC氨氮（水杨酸法）自动分析仪用户手册_V2.0_fmt_pdf.pdf';
import CODPdf from '../../pdf/LOC化学需氧量自动分析仪用户手册_V2.0_fmt_pdf.pdf';
import TNPdf from '../../pdf/LOC总磷自动分析仪用户手册_V2.0_fmt_pdf.pdf';
import ClPdf from '../../pdf/LOC余氯（DPD）自动分析仪用户手册_V2.0_fmt_pdf.pdf';

export const MonitorDetailObj = {
  items: [
    {
      id:'NH3N',
      name: 'LOC氨氮⾃动分析仪（零试剂型）',
      img: NH3N,
      link: NH3NPdf,
      description: '氨氮在线分析仪（零试剂型）是毫厘科技依据离⼦选择电极技术研发⽽成的实时氨氮监测设备。离\n' +
        '⼦选择电极技术是⼀种依靠离⼦载体选择性结合⽔中特定离⼦来测算样品中⽬标离⼦的电化学传感\n' +
        '器技术，⽆需使⽤任何药剂即可实现快速的在线氨氮测定。离⼦选择电极技术不仅可以提供宽泛的\n' +
        '测量范围，⽽且可以在数秒内快速给出接近化学药剂法准确度的结果。模块的开发遵循《氨氮⽔质\n' +
        '在线⾃动监测仪器技术要求及检测⽅法》（ HJ 101-2019）并严格按照标准进⾏了全部性能指标的\n' +
        '测试。模块具备边缘算⼒，可以⽀持标准RS485输出，内置标准溶液可以进⾏远程质量控制。',
      advantage: ['⽆需使⽤任何药剂即可进⾏多达4个数量级的宽量程测量',
      'LOC芯⽚中内置pH和温度传感器，可以将铵离⼦浓度精确转换成氨氮浓度',
      'LOC芯⽚可以稳定使⽤6个⽉以上，使⽤时⽆需进⾏校准',
      'LOC芯⽚内置参考样品，定期校验来保证分析结果精确可靠'],
      settings: ['地表⽔中氨氮的连续在线监测',
      '污⽔处理设施进出⽔的⽇常连续监测',
      '⾬⽔井或泵站⽔质的连续监测和报警',
      '排放⼝的氨氮连续监测和报警',
      '⼯业或市政⽔处理过程监测'],
      info:[
        {
          name: '模块尺⼨',
          data: '150*95*100(⾼深宽)',
        },{
          name: '最优量程范围',
          data: '0 - 100 mg/L',
        },{
          name: '准确度',
          data: '±5% F.S. 或 ≤ ± 0.1 mg/L',
        },{
          name: '重复性',
          data: '± 5% F.S.',
        },{
          name: '零点漂移',
          data: '±5%'
        },{
          name: '量程漂移',
          data: '±5%'
        },{
          name: '检出限',
          data: '0.1mg/L'
        },{
          name: '实际⽔样⽐对试验',
          data: '±10%(平均误差)'
        },{
          name: '分辨率',
          data: '0.01mg/L'
        },{
          name: '使⽤温度',
          data: '5~45 ℃'
        },{
          name: '输出⽅式',
          data: 'RS485'
        }
      ],
    },
    {
      id:'organism',
      img: organism,
      link: organismPdf,
      name: 'LOC有机物⾃动分析仪',
      description: '有机物在线分析仪是毫厘科技依据紫外吸收法在LOC架构上开发实时在线监测设备，该设备主要利\n' +
        '⽤特征有机物在紫外区间的吸收光谱来和算法来推断样品中有机物的含量并换算成COD、BOD5或\n' +
        'TOC，⽆需使⽤任何药剂即可实现快速的测定。紫外吸收技术虽然仅特征有机物有响应，单经过合\n' +
        '适的算法可以实时获取接近湿化学法准确度的结果。模块的开发遵循《紫外（UV）吸收⽔质⾃动在\n' +
        '线监测仪技术要求》（HJ/T 191—2005）并严格按照标准进⾏了全部性能指标的测试。模块具备边\n' +
        '缘算⼒，可以⽀持标准RS485输出，内置标准溶液可以进⾏远程质量控制。',
      advantage:['⽆需使⽤任何药剂即可快速对多种类型样品中的有机物进⾏测量',
      'LOC芯⽚内置的光窗经过特殊材料处理具有⾼疏⽔性，不易附着微⽣物',
      'LOC芯⽚可以稳定使⽤6个⽉以上，污染以后可以快速更换',
      '结果可以转化成⾼锰酸盐指数（CODMn）、化学需氧量（CODCr）或总有机碳（TOC）',
      'LOC芯⽚内置零点，可以远程检验光窗污染情况来保证分析结果精确可靠'],
      settings: ['地表⽔连续在线有机污染物监测','污⽔处理设施进出⽔⽇常连续监测','⾬⽔井或泵站⽔质的连续监测和报警', '排放⼝的有机物连续监测和报警', '⼯业或市政⽔处理过程监测'],
      info:[
        {
          name: '模块尺⼨',
          data: '150*80*100(⾼深宽)',
        },{
          name: '最优量程范围',
          data: '5 - 100 mg/L',
        },{
          name: '准确度',
          data: '±5% F.S. 或 ≤ ± 1.0mg/L',
        },{
          name: '重复性',
          data: '± 5% F.S.',
        },{
          name: '零点漂移',
          data: '±5%'
        },{
          name: '量程漂移',
          data: '±5%'
        },{
          name: '检出限',
          data: '5.0 mg/L'
        },{
          name: '实际⽔样⽐对试验',
          data: '±15%(平均误差)'
        },{
          name: '分辨率',
          data: '0.1mg/L'
        },{
          name: '使⽤温度',
          data: '5~45 ℃'
        },{
          name: '输出⽅式',
          data: 'RS485'
        }
      ],

    },
    {
      id:'NH3NSecond',
      name: 'LOC氨氮⾃动分析仪（⽔杨酸法）',
      link: NH3NSecondPdf,
      img: NH3NSecond,
      description: '⽔杨酸法氨氮在线分析仪是毫厘科技依托LOC架构开发的新型湿化学法氨氮连续在线监测设备，系\n' +
        '统成功将微流控技术⽤于⽔质监测领域。系统采⽤经典的⽔杨酸法作为测试⽅法，仅需要微升级的\n' +
        '药剂和样品即可以在极短的时间内完成⾼质量的氨氮测试，在多项精度和准确度的指标上与⼈⼯实\n' +
        '验室分析结果相当。模块的开发遵循《氨氮⽔质在线⾃动监测仪器技术要求及检测⽅法》（ HJ\n' +
        '101-2019）并严格按照标准进⾏了全部性能指标的测试，测试⽅法遵循 《⽔质 氨氮的测定 ⽔杨酸\n' +
        '分光光度法》（HJ 536-2009）。 模块具备边缘算⼒，可以⽀持标准RS485输出，内置标准溶液可\n' +
        '以进⾏远程质量控制。',
      advantage: ['单次分析的药剂量⼩于10微升，废液量不到传统⽅法的1%，⼤⼤降低了运维费⽤和废液量',
      '试剂仓和LOC芯⽚合⼆为⼀，整体作为系统耗材使⽤','更换简单，⽆需专业⼈⼠，系统会根据消耗量⾃动提示更换',
      '试剂仓采⽤阻氧设计，每个试剂仓可以使⽤60天以上','具备⾼浓度样品稀释功能，可以覆盖3个数量级的测量范围，适合多种场景使⽤'],
      settings: ['地表⽔中氨氮的连续在线监测', '集中或分散式污⽔处理设施进出⽔质量控制', '排放⼝的氨氮连续监测和报警', '科学研究领域微量样品氨氮监测'],
      info:[
        {
          name: '模块尺⼨',
          data: '150*100*90(⾼深宽)',
        },{
          name: '最优量程范围',
          data: '0 - 50 mg/L',
        },{
          name: '准确度',
          data: '±5% F.S. 或 ≤ ± 0.1 mg/L',
        },{
          name: '重复性',
          data: '± 5% F.S.',
        },{
          name: '零点漂移',
          data: '±5%'
        },{
          name: '量程漂移',
          data: '±5%'
        },{
          name: '检出限',
          data: '0.05mg/L'
        },{
          name: '实际⽔样⽐对试验',
          data: '±10%(平均误差)'
        },{
          name: '分辨率',
          data: '0.01mg/L'
        },{
          name: '使⽤温度',
          data: '5~45 ℃'
        },{
          name: '输出⽅式',
          data: 'RS485'
        }
      ],
    },
    {
      id:'TN',
      link: TNPdf,
      name: 'LOC总磷⾃动分析仪（钼酸盐法）',
      img: TN,
      description: '钼酸盐法总磷分析仪是毫厘科技依托LOC架构开发的新型湿化学法总磷连续在线监测设备，系统成\n' +
        '功将微流控技术和纳⽶管和紫外光协同催化消解技术⽤于⽔质监测领域。系统采⽤经典的钼酸盐法\n' +
        '作为测试⽅法，仅需要微升级的药剂盒样品即可以在极短的时间内完成⾼质量的总磷测试，在多项\n' +
        '精度和准确度的指标上与⼈⼯实验室分析结果相当。模块的开发遵循《总磷⽔质⾃动分析仪技术要\n' +
        '求》（ HJ/T103-200），并严格按照标准进⾏了全部性能指标的测试，《⽔质 总磷的测定 钼酸铵\n' +
        '分光光度法》（GB 11893-89）。 模块具备边缘算⼒，可以⽀持标准RS485输出，内置标准溶液可\n' +
        '以进⾏远程质量控制。',
      advantage: ['单次分析的药剂量⼩于10微升，废液量不到传统⽅法的1%，⼤⼤降低了运维费⽤和废液量',
        '试剂仓和LOC芯⽚合⼆为⼀，整体作为系统耗材使⽤',
        '更换简单，⽆需专业⼈⼠，系统会根据消耗量⾃动提示更换',
        '试剂仓采⽤阻氧设计，每个试剂仓可以使⽤90天以上',
        '系统可以快速测量⽔中的正磷酸盐，搭配专属消解模块使⽤可以完成总磷的测量'],
      settings: ['河道或湖库等地表⽔中总磷或磷酸盐的连续在线监测', '集中或分散式污⽔处理设施进出⽔质量控制', '排放⼝的总磷连续监测和报警', '科学研究领域微量样品磷酸盐或总磷监测'],
      info:[
        {
          name: '模块尺⼨',
          data: '150*100*90(⾼深宽)',
        },{
          name: '最优量程范围',
          data: '0 - 2 mg/L',
        },{
          name: '准确度',
          data: '±5% F.S. 或 ≤ ± 0.1 mg/L',
        },{
          name: '重复性',
          data: '± 5% F.S.',
        },{
          name: '零点漂移',
          data: '±5%'
        },{
          name: '量程漂移',
          data: '±5%'
        },{
          name: '检出限',
          data: '0.01mg/L'
        },{
          name: '实际⽔样⽐对试验',
          data: '±10%(平均误差)'
        },{
          name: '分辨率',
          data: '0.01mg/L'
        },{
          name: '使⽤温度',
          data: '5~45 ℃'
        },{
          name: '输出⽅式',
          data: 'RS485'
        },
      ],
    },
    {
      id:'COD',
      link: CODPdf,
      name: 'LOC化学需氧量⾃动分析仪（湿化学法）',
      img: COD,
      description: '湿化学法型化学需氧量在线分析仪是毫厘科技依托LOC架构开发的全球⾸款采⽤微升级试剂和湿化\n' +
        '学法进⾏化学需氧量测定的系统，可以使⽤经典重铬酸钾法和⾼锰酸盐法来完成样品中有机物的测\n' +
        '定。系统采⽤了现⽬前全球最顶尖的微流芯⽚设计，可以在⾼达175摄⽒度的⾼温和2.0Mpa的⾼压\n' +
        '环境下完成化学氧化反应，在多项精度和准确度的指标上可以和⼈⼯实验室分析结果相当。模块的\n' +
        '开发遵循《化学需氧量（CODCr）⽔质在线⾃动监测仪器技术要求及检测⽅法 》（HJ\n' +
        '377-2019），并严格按照标准进⾏了全部性能指标的测试，测试⽅法遵循《⽔质化学需氧量的测定\n' +
        '重铬酸盐法》（HJ 828-2017）。 模块具备边缘算⼒，可以⽀持标准RS485输出，内置标准溶液可\n' +
        '以进⾏远程质量控制。',
      advantage: ['浓硫酸硫酸等强腐蚀性氧化剂的使⽤量降低了99%，⼤⼤降低了危险品使⽤和储存⻛险',
        '根据使⽤场景的不同可以使⽤重铬酸钾或⾼锰酸钾作为氧化剂',
        '根据样品的浓度⾃动选择稀释倍数并⾃动完成样品的稀释',
        '药剂仓可以使⽤12个⽉以上，内置标准溶液进⾏远程校准和质量管理',
        '可以搭配毫厘科技有机物分析仪（光学法）⼀起使⽤进⾏⾼密度的实时监测'],
      settings: ['环保领域的有机物污染源监测',
        '河道和湖库地表⽔以及地下⽔监测',
        '⾬⽔井或泵站⽔质的连续监测和报警',
        '排放⼝的COD连续监测和报警',
        '⼯业或市政⽔处理过程监测'],
      info:[
        {
          name: '模块尺⼨',
          data: '160*110*160(⾼深宽)',
        },{
          name: '最优量程范围',
          data: '10 - 500 mg/L',
        },{
          name: '准确度',
          data: '±5% F.S. 或 ≤ ± 1 mg/L',
        },{
          name: '重复性',
          data: '± 10% F.S.',
        },{
          name: '零点漂移',
          data: '±5%'
        },{
          name: '量程漂移',
          data: '±5%'
        },{
          name: '检出限',
          data: '10mg/L'
        },{
          name: '实际⽔样⽐对试验',
          data: '±10%(平均误差)'
        },{
          name: '分辨率',
          data: '1mg/L'
        },{
          name: '使⽤温度',
          data: '5~45 ℃'
        },{
          name: '输出⽅式',
          data: 'RS485'
        },
      ],
    },
    {
      id:'NO3',
      link: NO3Pdf,
      name: 'LOC-硝酸盐⾃动分析仪',
      img: NO3,
      description: '硝酸盐氮在线分析仪是毫厘科技依据深紫外光谱法在LOC架构上开发的硝酸盐氮线实时监测设备，\n' +
        '该设备主要利⽤硝酸根在深紫外区间的吸收光谱和算法来计算样品中硝酸盐的含量，⽆需使⽤任何\n' +
        '药剂即可实现快速硝酸盐氮的测定。模块采⽤独特的多光源技术和硝酸盐选择技术，可以有效屏蔽\n' +
        '有机物在紫外区间的吸收对结果产⽣的⼲扰，准确度可接近离⼦⾊谱，结合零试剂型氨氮⾃动分析\n' +
        '仪使⽤，可以快速推测样品中总氮浓度。模块的开发遵循《《总氮⽔质⾃动分析仪技术要求》\n' +
        '（ HJ/T102-2003）并严格按照标准进⾏了全部性能指标的测试。模块具备边缘算⼒，可以⽀持标\n' +
        '准RS485输出，内置标准溶液可以进⾏远程质量控制。',
      advantage: ['⽆需使⽤任何药剂即可进⾏⾼精度的硝酸盐氮连续测量',
        '配合⽆试剂型氨氮分析仪使⽤可以实时获取样品的总氮浓度',
        'LOC芯⽚光窗采⽤特殊材质制成，不易污染，可以快速进⾏更换',
      'LOC芯⽚内置参考样品，定期对结果结果进⾏校验来保证分析结果精确可靠'],
      settings: ['河道或湖库地表⽔硝酸盐氮或总氮连续在线监测',
        '反硝化滤池进⽔监测或过程控制',
        '集中式或分散式⽔处理设施出⽔质量管理',
        '排放⼝的总氮连续监测和报警',
        '⼯业⽔处理过程过程监测'],
      info:[
        {
          name: '模块尺⼨',
          data: '160*110*140(⾼深宽)',
        },{
          name: '最优量程范围',
          data: '0 - 50 mg/L',
        },{
          name: '准确度',
          data: '±5% F.S. 或 ≤ ± 0.1 mg/L',
        },{
          name: '重复性',
          data: '± 5% F.S.',
        },{
          name: '零点漂移',
          data: '±5%'
        },{
          name: '量程漂移',
          data: '±5%'
        },{
          name: '检出限',
          data: '0.5mg/L'
        },{
          name: '实际⽔样⽐对试验',
          data: '±10%(平均误差)'
        },{
          name: '分辨率',
          data: '0.1mg/L'
        },{
          name: '使⽤温度',
          data: '5~45 ℃'
        },{
          name: '输出⽅式',
          data: 'RS485'
        },
      ],
    },
    {
      id:'Cl',
      link: null,
      name: 'LOC-余氯⾃动分析仪',
      img: Cl,
      description: 'DPD法余氯分析仪是毫厘科技依托LOC架构开发的新型湿化学法余氯连续在线监测设备。系统采⽤\n' +
        '经典的DPD分光光度法法作为测试⽅法，仅需要微升级的药剂盒样品即可以在极短的时间内完成极\n' +
        '低浓度的余氯测试，在多项精度和准确度的指标上与⼈⼯实验室分析结果相当。模块具备边缘算\n' +
        '⼒，可以⽀持标准RS485输出，内置标准溶液可以进⾏远程质量控制。',
      advantage: ['单次分析的药剂量⼩于10微升，废液量不到传统⽅法的1%，⼤⼤降低了运维费⽤和废液量',
        '试剂仓和LOC芯⽚合⼆为⼀，整体作为系统耗材使⽤',
        '更换简单，⽆需专业⼈⼠，系统会根据消耗量⾃动提示更换',
        '试剂仓采⽤阻氧设计，每个试剂仓可以使⽤90天以上',
        '系统可以快速测量⽔中的余氯，适合⼤⾯积快熟部署'],
      settings: ['⾃来⽔末端节点连续在线监测',
        '商业或家⽤泳池⽔连续监测',
        '⼆次供⽔设施或输配⽔设备',
        '科学研究领域微量样品余氯监测',],
      info:[
        {
          name: '模块尺⼨',
          data: '150*100*90(⾼深宽)',
        },{
          name: '最优量程范围',
          data: '0 - 0.5 mg/L',
        },{
          name: '准确度',
          data: '±5% F.S. 或 ≤ ± 0.1 mg/L',
        },{
          name: '重复性',
          data: '± 5% F.S.',
        },{
          name: '零点漂移',
          data: '±5%'
        },{
          name: '量程漂移',
          data: '±5%'
        },{
          name: '检出限',
          data: '0.005mg/L'
        },{
          name: '实际⽔样⽐对试验',
          data: '±10%(平均误差)'
        },{
          name: '分辨率',
          data: '0.001mg/L'
        },{
          name: '使⽤温度',
          data: '5~45 ℃'
        },{
          name: '输出⽅式',
          data: 'RS485'
        },
      ],
    }
  ]
}
